<template>
  <div>
    <vue-element-loading
      :active="appLoading"
      :is-full-screen="true"
      background-color="#FFFFFF"
      color="#005f32"
      spinner="bar-fade-scale"
    />
    <v-snackbar v-model="showsnackbar" color="black" right>
      <v-layout wrap justify-center>
        <v-flex text-left class="align-self-center">{{ msg }}</v-flex>
        <v-flex text-right>
          <v-btn small :ripple="false" text @click="showsnackbar = false">
            <v-icon style="color: white">mdi-close</v-icon>
          </v-btn>
        </v-flex>
      </v-layout>
    </v-snackbar>
    <v-layout wrap>
      <v-flex>
        <!-- <v-card> -->
        <v-card-title class="elevation-0">
          <span class="itemHeading">Apply for Salary advance</span>
          <v-spacer></v-spacer>
        </v-card-title>
        <v-layout wrap justify-start>
          <!-- <v-flex xs12 pa-4>
              <v-spacer></v-spacer>
            </v-flex> -->

          <v-flex xs12 align-self-center fill-height pa-0>
            <v-card tile flat>
              <v-form v-model="addcat2" ref="addcat2">
                <v-layout wrap v-if="appUser">
                  <v-flex
                    xs12
                    sm6
                    align-self-center
                    text-left
                    pa-2
                    v-if="appUser.name"
                  >
                    <v-text-field
                      v-model="appUser.name"
                      label="Name/Designation"
                      :rules="[rules.required]"
                      required
                      disabled
                      hide-details
                      outlined
                      type="text"
                      @wheel="$event.target.blur()"
                      dense
                    ></v-text-field>
                  </v-flex>
                  <v-flex xs12 sm6 align-self-center text-left pa-2>
                    <v-text-field
                      v-model="projectOrDivision"
                      label="Project/Division"
                      :rules="[rules.required]"
                      required
                      hide-details
                      outlined
                      type="text"
                      @wheel="$event.target.blur()"
                      dense
                    ></v-text-field>
                  </v-flex>
                  <v-flex xs12 sm6 align-self-center text-left pa-2>
                    <v-text-field
                      v-model="amount"
                      label="Amount"
                      :rules="[rules.required]"
                      required
                      hide-details
                      outlined
                      type="number"
                      @wheel="$event.target.blur()"
                      dense
                    ></v-text-field>
                  </v-flex>
                  <v-flex xs6 pa-2 align-self-start>
                    <v-menu
                      ref="menu"
                      v-model="menu"
                      :close-on-content-click="false"
                      :return-value.sync="advanceDate"
                      transition="scale-transition"
                      offset-y
                      min-width="auto"
                    >
                      <template v-slot:activator="{ on, attrs }">
                        <v-text-field
                          v-model="advanceDate"
                          label="Advance required by"
                          outlined
                          hide-details
                          readonly
                          dense
                          class="rounded-0"
                          v-bind="attrs"
                          v-on="on"
                        ></v-text-field>
                      </template>
                      <v-date-picker
                        v-model="advanceDate"
                        no-title
                        scrollable
                        @change="$refs.menu.save(advanceDate)"
                      >
                        <v-spacer></v-spacer>
                      </v-date-picker>
                    </v-menu>
                  </v-flex>
                  <v-flex xs12 sm6 align-self-center text-left pa-2>
                    <v-text-field
                      v-model="amountInWords"
                      label="Amount in words"
                      :rules="[rules.required]"
                      required
                      disabled
                      hide-details
                      outlined
                      type="text"
                      @wheel="$event.target.blur()"
                      dense
                    ></v-text-field>
                  </v-flex>
                  <v-flex xs12 sm6 align-self-center text-left pa-2>
                    <v-text-field
                      v-model="financialYear"
                      label="Financial Year"
                      :rules="[rules.required]"
                      required
                      hide-details
                      disabled
                      outlined
                      type="text"
                      @wheel="$event.target.blur()"
                      dense
                    ></v-text-field>
                  </v-flex>
                  <v-flex xs12 align-self-center text-left pa-2>
                    <v-radio-group v-model="type" row hide-details>
                      <v-radio label="In Cash" value="In Cash"></v-radio>
                      <v-radio label="Cheque" value="Cheque"></v-radio>
                      <v-radio label="DD" value="DD"></v-radio>
                      <v-radio
                        label="Bank Transfer"
                        value="Bank Transfer"
                      ></v-radio>
                    </v-radio-group>
                  </v-flex>
                  <v-flex xs12>
                    <v-layout wrap>
                      <v-flex xs6 align-self-center text-left pa-2>
                        <span
                          >Deductions for adjustment may please be made
                          from</span
                        >
                      </v-flex>
                      <v-flex xs6 align-self-center text-left pa-2>
                        <v-radio-group
                          v-model="deductionsForAdjustmentFrom"
                          row
                          hide-details
                        >
                          <v-radio
                            label="Current months salary onwards"
                            value="Current months salary onwards"
                          ></v-radio>
                          <v-radio
                            label="Next months salary onwards"
                            value="Next months salary onwards"
                          ></v-radio>
                        </v-radio-group>
                      </v-flex>
                    </v-layout>
                  </v-flex>
                  <v-flex xs12>
                    <v-layout wrap>
                      <v-flex xs6 align-self-center text-left pa-2>
                        <span>No. of installments</span>
                      </v-flex>
                      <v-flex xs6 align-self-center text-left pa-2>
                        <v-radio-group
                          v-model="numberOfInstallments"
                          row
                          hide-details
                        >
                          <v-radio label="One" value="One"></v-radio>
                          <v-radio label="Two" value="Two"></v-radio>
                          <v-radio label="Three" value="Three"></v-radio>
                        </v-radio-group>
                      </v-flex>
                    </v-layout>
                  </v-flex>
                </v-layout>
                <v-layout wrap>
                  <v-flex xs12 align-self-center text-center pa-2>
                    <v-layout wrap justify-center py-4>
                      <v-flex xs12 sm6 md4 lg3 align-self-center px-4>
                        <v-btn
                          color="#005f32"
                          dark
                          tile
                          large
                          class="rounded-xl"
                          block
                          @click="validateInput()"
                        >
                          Submit
                        </v-btn>
                      </v-flex>
                    </v-layout>
                  </v-flex>
                </v-layout>
              </v-form>
            </v-card>
          </v-flex>
        </v-layout>

        <!-- </v-card> -->
      </v-flex>
    </v-layout>
  </div>
</template>
  <script>
import axios from "axios";
export default {
  data() {
    return {
      showsnackbar: false,
      ServerError: false,
      msg: null,
      pages: 0,
      pageCount: 1,
      appLoading: false,
      //add
      projectOrDivision: null,
      amountInWords: null,
      financialYear: null,
      amount: null,
      advanceDate: null,
      type: null,
      numberOfInstallments: null,
      deductionsForAdjustmentFrom: null,
      addcat2: false,
      advanceData: {},
      rules: {
        required: (value) => !!value || "Required.",
      },
      menu: false,
    };
  },
  mounted() {
    this.getData();
    // this.financialYear = this.currentFinancialYear;
  },
  computed: {
    appUser() {
      return this.$store.state.userData;
    },
    userId() {
      return this.$store.state.userData._id;
    },
    // currentFinancialYear() {
    //   const today = new Date();
    //   const month = today.getMonth();
    //   const year = today.getFullYear();

    //   if (month >= 3) {
    //     // After March
    //     return `${year}-${year + 1}`;
    //   } else {
    //     // January to March
    //     return `${year - 1}-${year}`;
    //   }
    // },
  },
  watch: {
    amount: {
      handler(newValue) {
        if (newValue) {
          const numAmount = parseInt(newValue);
          if (!isNaN(numAmount)) {
            this.amountInWords = this.numberToWords(numAmount);
          }
        } else {
          this.amountInWords = "";
        }
      },
      immediate: true,
    },
  },
  methods: {
    close(item, i) {
      this.documentFile1.splice(i, 1);
    },
    validateInput() {
      if (!this.projectOrDivision) {
        this.msg = "Please provide project Or Division";
        this.showsnackbar = true;
        return;
      } else if (!this.amount) {
        this.msg = "Please provide amount";
        this.showsnackbar = true;
        return;
      } else if (!this.advanceDate) {
        this.msg = "Please provide advance date";
        this.showsnackbar = true;
        return;
      } else if (!this.type) {
        this.msg = "Please choose type";
        this.showsnackbar = true;
        return;
      } else if (!this.numberOfInstallments) {
        this.msg = "Please provide number of installments";
        this.showsnackbar = true;
        return;
      } else if (!this.deductionsForAdjustmentFrom) {
        this.msg = "Please provide deductions for adjustment from";
        this.showsnackbar = true;
        return;
      } else {
        this.applySalary();
      }
    },
    browseCover(event) {
      var got = event.target.files;
      if (got.length < 5) {
        for (let i = 0; i < got.length; i++) {
          this.documentFile1.push(got[i]);
        }
        console.log("file isss", this.documentFile1);
      } else {
        this.msg = "Maximum 3 can be uploaded";
        this.showSnackBar = true;
      }
    },
    getData() {
      this.appLoading = true;
      axios({
        url: "/salaryadvance/application/view",
        method: "POST",
        headers: {
          "x-auth-token": localStorage.getItem("token"),
        },
        data: {
          id: this.$route.query.id,
        },
      })
        .then((response) => {
          this.appLoading = false;
          if (response.data.status) {
            const advanceData = response.data.data;
            // Populate form fields with existing data
            this.projectOrDivision = advanceData.projectOrDivision;
            this.amount = advanceData.amount;
            this.advanceDate = this.formatDateForInput(advanceData.advanceDate);
            this.type = advanceData.type;
            this.numberOfInstallments = advanceData.numberOfInstallments;
            this.deductionsForAdjustmentFrom =
              advanceData.deductionsForAdjustmentFrom;
            this.financialYear = advanceData.fyear;
          }
        })
        .catch((err) => {
          this.appLoading = false;
          this.ServerError = true;
          console.log(err);
        });
    },
    formatDateForInput(dateString) {
      const date = new Date(dateString);
      return date.toISOString().split("T")[0]; // Returns YYYY-MM-DD format
    },
    applySalary() {
      axios({
        url: "salaryadvance/application/edit",
        method: "POST",
        data: {
          id: this.$route.query.id,
          employeeId: this.userId,
          nameOrDesgination: this.appUser.name,
          projectOrDivision: this.projectOrDivision,
          amount: this.amount,
          amountInWords: this.amountInWords,
          fyear: this.financialYear,
          advanceDate: this.advanceDate,
          type: this.type,
          numberOfInstallments: this.numberOfInstallments,
          deductionsForAdjustmentFrom: this.deductionsForAdjustmentFrom,
        },
        headers: {
          "x-auth-token": localStorage.getItem("token"),
        },
      })
        .then((response) => {
          this.appLoading = false;
          if (response.data.status == true) {
            this.msg = response.data.msg;
            this.showsnackbar = true;
            this.$router.push("/MySalaryAdvanceApplications");
            // this.clearOff();
          } else {
            this.msg = response.data.msg;
            this.showsnackbar = true;
          }
        })
        .catch((err) => {
          this.appLoading = false;
          this.ServerError = true;
          console.log(err);
        });
    },
    formatDate(item) {
      var dt = new Date(item);
      var day = dt.getDate();
      var year = dt.getFullYear();
      var hours = dt.getHours();
      var minutes = dt.getMinutes();
      dt = dt.toString();
      var ampm = hours >= 12 ? "pm" : "am";
      hours = hours % 12;
      hours = hours ? hours : 12;
      minutes = minutes < 10 ? "0" + minutes : minutes;
      var strTime =
        day +
        " " +
        dt.slice(4, 7) +
        " " +
        year +
        " , " +
        hours +
        ":" +
        minutes +
        " " +
        ampm;

      return strTime;
    },
    numberToWords(num) {
      const ones = [
        "",
        "One",
        "Two",
        "Three",
        "Four",
        "Five",
        "Six",
        "Seven",
        "Eight",
        "Nine",
      ];
      const tens = [
        "",
        "",
        "Twenty",
        "Thirty",
        "Forty",
        "Fifty",
        "Sixty",
        "Seventy",
        "Eighty",
        "Ninety",
      ];
      const teens = [
        "Ten",
        "Eleven",
        "Twelve",
        "Thirteen",
        "Fourteen",
        "Fifteen",
        "Sixteen",
        "Seventeen",
        "Eighteen",
        "Nineteen",
      ];

      const convertLessThanThousand = (n) => {
        if (n === 0) return "";

        let result = "";
        if (n >= 100) {
          result += ones[Math.floor(n / 100)] + " Hundred ";
          n %= 100;
        }

        if (n >= 20) {
          result += tens[Math.floor(n / 10)] + " ";
          n %= 10;
        } else if (n >= 10) {
          result += teens[n - 10] + " ";
          return result;
        }

        if (n > 0) {
          result += ones[n] + " ";
        }

        return result;
      };

      if (num === 0) return "Zero";

      let result = "";
      if (num >= 100000) {
        result += convertLessThanThousand(Math.floor(num / 100000)) + "Lakh ";
        num %= 100000;
      }

      if (num >= 1000) {
        result += convertLessThanThousand(Math.floor(num / 1000)) + "Thousand ";
        num %= 1000;
      }

      result += convertLessThanThousand(num);

      return result.trim() + " Rupees Only";
    },
  },
};
</script>
  